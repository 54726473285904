import React, { ReactNode } from "react";
import Head from "next/head";
import Footer from "components/layout/Footer";
import Nav from "components/nav/NavNew";

type Props = {
  children: ReactNode;
  title?: string;
};

const Layout = ({
  children,
  title = "Live streams for independent venues and artists - Zuma Live",
}: Props) => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta
          name="description"
          content="Zuma Live is a virtual tech platform empowering creators, concerts, podcasts, education, NFTs, livestreams, and virtual events."
        />
      </Head>
      <div className="bg-grayDarkest text-offWhite">
        <div className="flex flex-col justify-between h-screen">
          <header>
            <Nav />
          </header>
          {children}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
