import Image from "next/image";
import Link from "next/link";
import zumaLogo from "public/images/ZL_Logo.png";
import { socialMediaMap } from "./SocialMediaIcons";

const companyLinks = [
  {
    href: "#",
    label: "Artists & Bands",
  },
  {
    href: "#",
    label: "Venues",
  },
  {
    href: "/job-opportunities",
    label: "Job Opportunities",
  },
];

const resourcesLinks = [
  {
    href: "/faq",
    label: "Help Center",
  },
  {
    href: "/faq",
    label: "FAQ",
  },
];

const bottomLinks = [
  {
    href: "/terms",
    label: "Terms of Use",
  },
  {
    href: "/privacy-policy",
    label: "Privacy Policy",
  },
  {
    href: "/faq",
    label: "Customer Service",
  },
];

export default function Footer() {
  return (
    <footer className="pb-6 footer bg-gradient-to-t from-black">
      <div className="container">
        <nav className="flex flex-wrap justify-between w-full pt-8 pb-6 lg:grid-cols-5 md:pb-14 order-0 sm:gap-6 sm:grid sm:grid-cols-4">
          <div className="flex items-center justify-between w-full mb-6 lg:col-span-2 sm:block sm:flex-col sm:col-span-1 sm:mb-0">
            <Link href="/" className="relative inline-block w-24 h-20">
              <Image src={zumaLogo} alt="Zuma logo" />
            </Link>
          </div>
          <div>
            <h3>Company</h3>
            <ul>
              {companyLinks.map((link) => (
                <li key={link.label}>
                  <Link href={link.href}>{link.label}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3>Resources</h3>
            <ul>
              {resourcesLinks.map((link) => (
                <li key={link.label}>
                  <Link href={link.href}>{link.label}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3>Follow Us</h3>
            <ul>
              {socialMediaMap.map((link) => (
                <li key={link.id}>
                  <Link href={link.url}>{link.label}</Link>
                </li>
              ))}
            </ul>
          </div>
        </nav>
        <hr className="w-full mb-2 border-t-0 border-b border-grayDark border-list" />
        <div className="flex flex-col w-full md:flex-row md:justify-between">
          <div className="mt-4 text-gray-500">&copy; 2023 Zuma Live LLC.</div>
          <ul className="flex w-full mt-4 space-x-4 md:w-auto md:space-x-8">
            {bottomLinks.map((link) => (
              <li key={link.label}>
                <Link href={link.href} className="text-gray-500">
                  {link.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
}
