import {
  Bars3Icon,
  ShoppingBagIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
/* This example requires Tailwind CSS v2.0+ */
import { Popover, Transition } from "@headlessui/react";
import {
  useSessionContext,
  useSupabaseClient,
  useUser,
} from "@supabase/auth-helpers-react";
import React, { Fragment } from "react";

import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Loading from "components/Loading";
import useLocalStorage from "hooks/use-local-storage";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import logo from "public/images/ZL_Logo.png";

const navLinks = [
  {
    name: "Browse",
    description: "Browse all upcoming events",
    href: "/events",
  },
  {
    name: "My Shows",
    description: "Visit your dashboard",
    href: "/my-account",
  },
];

// @ts-ignore
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Nav() {
  const { isLoading } = useSessionContext();
  const router = useRouter();
  const [cartItem, setCartItem] = useLocalStorage("cartItem", "");
  const supabaseClient = useSupabaseClient();

  const user = useUser();

  //const [currentUser, setCurrentUser] = React.useState<null | ZumaUser>(null);
  const [scrollY, setScrollY] = React.useState(0);
  const [delayedCartItem, setDelayedCartItem] = React.useState("");

  React.useEffect(() => {
    if (cartItem) {
      setDelayedCartItem(cartItem);
    }
  }, [cartItem]);

  function logit() {
    setScrollY(window.pageYOffset);
  }

  React.useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });

  function handleLogout() {
    supabaseClient.auth.signOut();
    router.push("/");
  }

  /**
   * On Homepage make the navbar transparent by default, then
   * fade in on page scroll
   */
  let navClass = `fixed w-full z-20 transition-all duration-500`;
  navClass += `  ${
    scrollY < 300 ? `bg-gradient-to-b from-black` : `bg-black/90`
  }`;

  return (
    <Popover className={navClass}>
      <div className="flex items-center justify-between px-gr-5 py-gr-5 lg:px-gr-6 xl:px-gr-7 md:justify-start md:space-x-gr-5">
        <div className="flex justify-start lg:w-0 lg:flex-1">
          <Link href="/">
            <span className="sr-only">Zuma Live</span>
            <Image className="w-auto h-5" src={logo} alt="Zuma Live logo" />
          </Link>
        </div>
        <div className="-my-gr-2 -mr-gr-2 md:hidden">
          <Popover.Button className="inline-flex items-center justify-center text-gray-200 bg-transparent rounded-md p-gr-2 hover:bg-grayDarkest hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
            <span className="sr-only">Open menu</span>
            <Bars3Icon className="w-gr-6 h-gr-6" aria-hidden="true" />
          </Popover.Button>
        </div>
        <Popover.Group as="nav" className="hidden space-x-gr-6 md:flex">
          {navLinks.map((navLink) => (
            <Link href={navLink.href} key={navLink.name} className="nav-link">
              {navLink.name}
            </Link>
          ))}
        </Popover.Group>
        <div className="items-center justify-end hidden md:flex md:flex-1 lg:w-0">
          <>
            {isLoading && <Loading />}
            {!isLoading && !user && (
              <div className="space-x-gr-2">
                <Link
                  href={{
                    pathname: "/login",
                    query: {
                      defaultAction: "sign_in",
                    },
                  }}
                  className="nav-link"
                >
                  Sign in
                </Link>
                <Link href="/login" className="nav-link">
                  Sign up
                </Link>
              </div>
            )}
            {!isLoading && user && (
              <Popover.Group
                as="nav"
                className="hidden space-x-10 md:flex md:items-center"
              >
                <Popover className="relative mt-gr-2">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? "text-gray-300" : "text-gray-200",
                          "group inline-flex items-center rounded-md bg-transparent text-base font-medium hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2"
                        )}
                      >
                        <span>
                          <UserIcon className="w-gr-6 h-gr-6" />
                        </span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? "text-gray-300" : "text-gray-200",
                            "ml-2 h-5 w-5 group-hover:text-gray-300"
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute right-0 z-10 w-screen max-w-xs px-2 mt-3 transform sm:px-0">
                          <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="relative grid gap-6 px-5 py-6 bg-gradient-to-b from-grayDarkest to-gray-600 sm:gap-8 sm:p-8">
                              <Link
                                href="/my-account"
                                className="block p-2 -m-3 text-gray-200 rounded-md hover:text-gray-300"
                              >
                                My Account
                              </Link>
                              <a
                                href="#"
                                className="block p-2 -m-3 text-gray-200 rounded-md hover:text-gray-300"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLogout();
                                }}
                              >
                                Logout
                              </a>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </Popover.Group>
            )}
            {delayedCartItem && (
              <Link suppressHydrationWarning href="/cart" title="View Cart">
                <ShoppingBagIcon className="transition-all duration-500 w-gr-6 h-gr-6 ml-gr-6 animate-pulse " />
              </Link>
            )}
          </>
        </div>
      </div>
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 z-30 p-2 transition origin-top-right transform md:hidden"
        >
          <div className="relative z-30 divide-y-2 rounded-lg shadow-lg divide-grayDark bg-gradient-to-b from-black to-grayDark ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="w-auto h-gr-6"
                    src="/images/ZL_Logo.png"
                    alt="Zuma Live logo"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-200 bg-transparent rounded-md hover:bg-grayDarkest hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-600">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-gr-6">
                <nav className="grid grid-cols-1 gap-gr-5">
                  {navLinks.map((navLink) => (
                    <Link
                      key={navLink.name}
                      href={navLink.href}
                      className="flex items-center rounded-lg p-gr-4 -m-gr-3 hover:bg-grayDark"
                    >
                      {navLink.name}
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
            <div className="px-5 py-6">
              {isLoading && <Loading />}
              <div className="pb-gr-6">
                {cartItem && (
                  <Link href="/cart" className="w-full btn primary">
                    <ShoppingBagIcon className="w-auto h-gr-6 mr-gr-4 animate-pulse" />{" "}
                    View cart
                  </Link>
                )}
              </div>

              {!isLoading && user && (
                <div className="grid grid-cols-2 gap-4">
                  <Link
                    href="/my-account"
                    className="text-base font-medium text-gray-300 hover:text-gray-100"
                  >
                    My Account
                  </Link>
                  <a
                    href={`#`}
                    className="text-base font-medium text-gray-300 hover:text-gray-100"
                    onClick={(e) => {
                      e.preventDefault();
                      handleLogout();
                    }}
                  >
                    Logout
                  </a>
                </div>
              )}

              {!isLoading && !user && (
                <div className="mt-gr-6 space-y-gr-6">
                  <Link href="/login" className="w-full btn">
                    Sign up
                  </Link>
                  <p className="text-base font-medium text-center text-gray-200 mt-gr-6">
                    Existing customer?{" "}
                    <Link href="/login" className="btn ml-gr-4">
                      Log in
                    </Link>
                  </p>
                </div>
              )}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
